<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Latest Donations</h4>
            <h6 class="card-subtitle">A list of recent donations</h6>
        </div>
        <div v-if="isLoading">Loading...</div>
        <table class="table" v-else-if="donations.length > 0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th> 
                    <th>Amount</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(donation, i) in donations" :key="i">
                    <td>{{ $moment(donation.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a") }}</td>
                    <td>{{ donation.name }}</td>
                    <td>{{ donation.email }}</td>
                    <td>{{ donation.currency + ' ' + donation.amount }}</td>
                    <td>{{ donation.status }}</td>
                </tr>
            </tbody>
        </table>
        <div class="notfound" v-else>No donations found</div>
        <!-- <pre>{{ donations }}</pre> -->
    </div>
</template>

<script>
export default {
    data(){
        return {
            donations: [],
            isLoading: false,
        }
    },
    created(){
        this.load()
    },
    methods: {
        async load(){
            this.isLoading = true
            try {
                const snap = await this.$db.collection("donations").orderBy("createdAt", "desc").limit(5).get()
                snap.forEach(doc => {
                    this.donations.push({ id: doc.id, ...doc.data() })
                })
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },
        view(id){
            this.$router.push("/donations/d/" + id)
        },
    }
}
</script>


<style scoped>
.listview__img_donations {
    width: 40.50px;
    height: 40.50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1.2rem;
}
.listview__heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
</style>